const DASHBOARD_BY_NUMBERS_ROWS_DICTIONARY = {
  totalAmount: 'Total appts booked',
  avgAppsPerDay: 'Total appts booked / day',
  totalCompleted: 'Total appts completed',
  avgCompletedPerDay: 'Total appts completed / day',
  totalCancelled: 'Total appts cancelled',
  totalRevenue: 'Total revenue',
  avgRevenuePerAppt: 'Avg revenue per appt',
}
export { DASHBOARD_BY_NUMBERS_ROWS_DICTIONARY as default }
