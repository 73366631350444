<template>
  <b-overlay
    :show="isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <b-card
      v-if="tableData"
      no-body
      class="card-company-table"
    >
      <b-table
        :items="tableData"
        responsive
        :fields="fields"
        class="mb-0"
        show-empty
      >
        <!-- Time -->
        <template #cell(starts_at)="data">
          <div class="d-flex align-items-center">
            <span>{{ data.item.starts_at | hours }}</span>
          </div>
        </template>

        <!-- Customer name -->
        <template #cell(customer)="data">
          <div class="d-flex align-items-center">
            <span>{{ data.item.customer }}</span>
          </div>
        </template>

        <!-- Phone -->
        <template #cell(phone)="data">
          <div class="d-flex align-items-center">
            <span>{{ data.item.phone }}</span>
          </div>
        </template>

        <!-- Email -->
        <template #cell(email)="data">
          <div class="d-flex align-items-center">
            <span>{{ data.item.email }}</span>
          </div>
        </template>

        <!-- Services -->
        <template #cell(services)="data">
          <div class="d-flex align-items-center">
            <span>{{ data.item.services }}</span>
          </div>
        </template>

        <!-- Amount -->
        <template #cell(amount)="data">
          {{ '$' + data.item.amount }}
        </template>

        <template #empty="scope">
          <div class="d-flex align-items-center">
            <span>{{ scope.emptyText }}</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BTable, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  filters: {
    hours(date) {
      return moment(date, 'yyyy-MM-DD HH:mm').format('h:mm a')
    },
  },
  components: {
    BOverlay,
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    isPending: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        { key: 'starts_at', label: 'TIME' },
        { key: 'customer', label: 'CUSTOMER NAME' },
        { key: 'phone', label: 'PHONE NUMBER' },
        { key: 'email', label: 'EMAIL' },
        { key: 'services', label: 'SERVICE NAME' },
        { key: 'amount', label: 'PRICE' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
