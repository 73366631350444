<template>
  <div>
    <h4 class="mb-2 mt-1">
      Appts and Revenue
    </h4>
    <b-overlay
      :show="isPending"
      variant="secondary"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="lg"
    >
      <b-card
        v-if="chartData.length > 0"
        no-body
      >
        <b-card-body>
          <!-- apex chart -->
          <vue-apex-charts
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :series="servicesChart.series"
            :options="servicesChart.chartOptions"
          />
          <div class="pt-25">
            <b-row class="mb-1 font-medium-2">
              <b-col sm="4">
                <span class="font-weight-bolder">Service</span>
              </b-col>
              <b-col
                sm="4"
                class="text-center"
              >
                <span class="font-weight-bolder">Appts</span>
              </b-col>
              <b-col
                sm="4"
                class="text-right"
              >
                <span class="font-weight-bolder">Revenue</span>
              </b-col>
            </b-row>
            <b-row
              v-for="(data, index) in chartData"
              :key="index"
              class="d-flex justify-content-between"
              :class="index === Object.keys(chartData).length - 1 ? '':'mb-1'"
            >
              <b-col sm="4">
                <span>{{ data.name }}</span>
              </b-col>
              <b-col
                sm="4"
                class="text-center"
              >
                <span>{{ data.quantity }}</span>
              </b-col>
              <b-col
                sm="4"
                class="text-right"
              >
                <span>${{ data.amount }}</span>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-card>
      <b-card
        v-if="chartData.length === 0"
      >
        <div
          class="d-flex align-items-center"
        >
          <span>There are no data to show</span>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardBody,
    BOverlay,
    BRow,
    BCol,
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    isPending: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      servicesChart: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [],
          dataLabels: { enabled: true },
          legend: { show: true },
          stroke: {
            width: 5,
          },
        },
      },
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        const values = this.getValuesToChart()
        this.servicesChart.series = values.values
        this.servicesChart.chartOptions = {
          ...this.servicesChart.chartOptions,
          ...{
            labels: [
              ...values.options,
            ],
          },
        }
      },
    },
  },
  methods: {
    getValuesToChart() {
      const valuesToChart = []
      let optionsToChart = []
      this.chartData.forEach(service => {
        valuesToChart.push(service.quantity)
        optionsToChart = [...optionsToChart, service.name]
      })
      return {
        values: valuesToChart,
        options: optionsToChart,
      }
    },
  },
}
</script>
