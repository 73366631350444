<template>
  <div>
    <h4 class="mb-2 mt-1">
      By the numbers
    </h4>
    <b-overlay
      :show="isPending"
      variant="secondary"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="lg"
    >
      <b-card
        v-if="tableData.length > 0"
        class="card-transaction"
        no-body
      >
        <b-card-header>
          <b-card-title>Current month</b-card-title>
        </b-card-header>

        <b-card-body>
          <div
            v-for="data in tableData"
            :key="data.key"
            class="transaction-item"
          >
            <b-media no-body>
              <b-media-body>
                <h6 class="transaction-title">
                  {{ DASHBOARD_BY_NUMBERS_ROWS_DICTIONARY[data.key] }}
                </h6>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
            >
              <span
                v-if="isMoneyType(DASHBOARD_BY_NUMBERS_ROWS_DICTIONARY[data.key])"
              >$</span>
              {{ data.value }}
            </div>
          </div>
        </b-card-body>
      </b-card>
      <b-card
        v-if="tableData.length === 0"
      >
        <div
          class="d-flex align-items-center"
        >
          <span>There are no data to show</span>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BOverlay,
} from 'bootstrap-vue'
import DASHBOARD_BY_NUMBERS_ROWS_DICTIONARY from '@/dictionaries/dashboardByNumbersRowDictionary'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BOverlay,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    isPending: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      DASHBOARD_BY_NUMBERS_ROWS_DICTIONARY,
    }
  },
  methods: {
    isMoneyType(value) {
      return value.toLowerCase().includes('revenue')
    },
  },
}
</script>
