<template>
  <b-overlay
    :show="false"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <b-row>
      <b-col
        md="12"
      >
        <h4 class="mb-2 mt-1">
          Top summary
        </h4>
      </b-col>
      <b-col
        md="12"
      >
        <stylist-dashboard-top-summary />
      </b-col>
      <b-col
        md="12"
      >
        <h4 class="mb-2 mt-1">
          Today's schedule
        </h4>
      </b-col>
      <b-col
        md="12"
      >
        <stylist-dashboard-schedule-table
          :table-data="scheduleTodayStore.response"
          :is-pending="scheduleTodayStore.isPending"
        />
      </b-col>
      <b-col
        md="12"
      >
        <h4 class="mb-2 mt-1">
          Tomorrow's schedule
        </h4>
      </b-col>
      <b-col
        md="12"
      >
        <stylist-dashboard-schedule-table
          :table-data="scheduleTomorrowStore.response"
          :is-pending="scheduleTomorrowStore.isPending"
        />
      </b-col>
      <b-col
        md="6"
      >
        <stylist-dashboard-byNumbers-table
          :table-data="byNumbersStore.response"
          :is-pending="byNumbersStore.isPending"
        />
      </b-col>
      <b-col
        md="6"
      >
        <stylist-dashboard-services-chart
          :is-pending="servicesGroupStore.isPending"
          :chart-data="servicesGroupStore.response"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import StylistDashboardTopSummary from '@/components/stylist/stylist-dashboard/StylistDashboardTopSummary.vue'
import StylistDashboardScheduleTable from '@/components/stylist/stylist-dashboard/StylistDashboardScheduleTable.vue'
import StylistDashboardByNumbersTable from '@/components/stylist/stylist-dashboard/StylistDashboardByNumbersTable.vue'
import StylistDashboardServicesChart from '@/components/stylist/stylist-dashboard/StylistDashboardServicesChart.vue'
import { mapActions, mapState } from 'vuex'

/* eslint-disable global-require */
export default {
  components: {
    StylistDashboardTopSummary,
    StylistDashboardByNumbersTable,
    StylistDashboardScheduleTable,
    StylistDashboardServicesChart,
    BOverlay,
    BCol,
    BRow,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('StylistDashboardStoreModule', {
      scheduleTodayStore: state => state.scheduleToday,
      scheduleTomorrowStore: state => state.scheduleTomorrow,
      byNumbersStore: state => state.byNumbers,
      servicesGroupStore: state => state.servicesGroup,
    }),
  },
  created() {
    this.fetchScheduleToday()
    this.fetchScheduleTomorrow()
    this.fetchByNumbers()
    this.fetchServicesGroup()
  },
  methods: {
    ...mapActions('StylistDashboardStoreModule', [
      'fetchScheduleToday',
      'fetchScheduleTomorrow',
      'fetchByNumbers',
      'fetchServicesGroup',
    ]),
  },
}
/* eslint-disable global-require */
</script>
