<template>
  <div>
    <b-row
      v-if="trialTime"
    >
      <b-col
        md="12"
      >

        <div class="card">
          <div class="card-body">
            Trial time left: <span>{{ trialTime }}</span>
          </div>

        </div>

      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="3"
        md="3"
        sm="6"
      >
        <stylist-dashboard-statistic-card
          icon="BookOpenIcon"
          :statistic="appointmentsCountBookedTodayStore.response"
          :is-pending="appointmentsCountBookedTodayStore.isPending"
          statistic-title="Appointments booked today"
          color="primary"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
        sm="6"
      >
        <stylist-dashboard-statistic-card
          icon="DollarSignIcon"
          :statistic="appointmentsSumAmountBookedTodayStore.response | amount"
          :is-pending="appointmentsSumAmountBookedTodayStore.isPending"
          statistic-title="Booked today"
          color="primary"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
        sm="6"
      >
        <stylist-dashboard-statistic-card
          icon="DollarSignIcon"
          :statistic="appointmentsSumAmountSalesTodayStore.response | amount"
          :is-pending="appointmentsSumAmountSalesTodayStore.isPending"
          statistic-title="Sales today"
          color="primary"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
        sm="6"
      >
        <stylist-dashboard-statistic-card
          icon="UserIcon"
          :statistic="totalUsersStore.response"
          :is-pending="totalUsersStore.isPending"
          statistic-title="Total clients"
          color="primary"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import StylistDashboardStatisticCard from '@/components/stylist/stylist-dashboard/StylistDashboardStatisticCard.vue'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

/* eslint-disable global-require */
export default {
  components: {
    BCol,
    BRow,
    StylistDashboardStatisticCard,
  },
  filters: {
    amount(amount) {
      return `$${Number(amount).toFixed(2)}`
    },
  },
  data() {
    return {
      trialTime: false,
      trialTimeout: null,
    }
  },
  computed: {
    ...mapState('StylistDashboardStoreModule', {
      totalUsersStore: state => state.totalUsers,
      appointmentsCountBookedTodayStore: state => state.appointmentsCountBookedToday,
      appointmentsSumAmountBookedTodayStore: state => state.appointmentsSumAmountBookedToday,
      appointmentsSumAmountSalesTodayStore: state => state.appointmentsSumAmountSalesToday,
    }),
  },
  created() {
    this.fetchTotalUsers()
    this.fetchAppointmentsCountBookedToday()
    this.fetchAppointmentsSumAmountBookedToday()
    this.fetchAppointmentsSumAmountSalesToday()
  },
  mounted() {
    this.trialCheck()
  },
  unmounted() {
    if (this.trialTimeout) {
      clearTimeout(this.trialTimeout)
    }
  },
  methods: {
    ...mapActions('StylistDashboardStoreModule', [
      'fetchTotalUsers',
      'fetchAppointmentsCountBookedToday',
      'fetchAppointmentsSumAmountBookedToday',
      'fetchAppointmentsSumAmountSalesToday',
    ]),
    ...mapActions('SubscriptionStoreModule', [
      'checkStylistSubscriptionStatus',
    ]),
    async trialCheck() {
      const response = await this.checkStylistSubscriptionStatus()
      const { now, subscription } = response.data

      let timeleft = subscription.current_period_end - now

      if (subscription.status !== 'trialing') {
        this.trialTime = false
        return
      }

      const nextTrialTick = () => {
        delete this.trialTimeout

        if (timeleft <= 0) {
          window.location.reload()
          return
        }

        const mtime = moment.utc(timeleft * 1000)
        const days = mtime.format('DD')
        const time = mtime.format('HH:mm')

        this.trialTime = (days > 0) ? `${days} days` : time
        this.trialTimeout = setTimeout(nextTrialTick, 1000)

        timeleft -= 1
      }

      nextTrialTick()
    },
  },
}
/* eslint-disable global-require */
</script>
